import React from "react";
import "./svgComp.scss";

export const DownloadIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
      version="1.1"
    >
      <g id="Symbols" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <path
            d="M14.7451689,0.5 L17.6000166,0.5 L17.6000166,0.5 C18.6493485,0.5 19.5,1.35065898 19.5,2.4 L19.5,17.6 C19.5,18.649341 18.6493485,19.5 17.6000166,19.5 L2.40014813,19.5 C1.35081619,19.5 0.5,18.649341 0.5,17.6 L0.5,2.59834976 C0.500006448,2.42833283 0.52168548,2.25900978 0.56451907,2.09447698 C0.841250823,1.03149233 1.57615001,0.5 2.76921664,0.5 C3.60474999,0.5 4.44028334,0.5 5.2758167,0.5 L5.2758167,0.5"
            id="矩形"
            strokeLinecap="round"
          ></path>
          <path
            d="M10.0464738,5 C10.3226162,5 10.5464738,5.22385763 10.5464738,5.5 L10.5462003,12.329 L13.2083594,9.66881624 C13.4036216,9.4735541 13.7202041,9.4735541 13.9154662,9.66881624 C14.1107283,9.86407839 14.1107283,10.1806609 13.9154662,10.375923 L10.3799323,13.9114569 C10.2422474,14.0491418 10.0442414,14.0897412 9.87098255,14.0332551 C9.78533618,14.0109546 9.70412783,13.9669608 9.6371808,13.9000138 L6.1016469,10.3644799 C5.90638475,10.1692177 5.90638475,9.85263525 6.1016469,9.65737311 C6.29690904,9.46211096 6.61349153,9.46211096 6.80875368,9.65737311 L9.54620029,12.395 L9.54647382,5.5 C9.54647382,5.22385763 9.77033144,5 10.0464738,5 Z"
            id="形状结合"
            className="svg"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const AddplayIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
      viewBox="0 0 20 20"
      version="1.1"
    >
      <g id="控件" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="play-bar_nar" transform="translate(-1269.000000, -36.000000)">
          <g id="player-bar">
            <g id="play-bar">
              <g
                id="btn_add-playlist"
                transform="translate(1269.000000, 36.000000)"
              >
                <path
                  d="M10,4 C10.2761424,4 10.5,4.22385763 10.5,4.5 L10.5,9.5 L15.5,9.5 C15.7761424,9.5 16,9.72385763 16,10 C16,10.2761424 15.7761424,10.5 15.5,10.5 L10.5,10.5 L10.5,15.5 C10.5,15.7761424 10.2761424,16 10,16 C9.72385763,16 9.5,15.7761424 9.5,15.5 L9.5,10.5 L4.5,10.5 C4.22385763,10.5 4,10.2761424 4,10 C4,9.72385763 4.22385763,9.5 4.5,9.5 L9.5,9.5 L9.5,4.5 C9.5,4.22385763 9.72385763,4 10,4 Z"
                  id="Icon"
                  className="svg"
                ></path>
                <rect
                  id="矩形"
                  x="0.5"
                  y="0.5"
                  width="19"
                  height="19"
                  rx="2"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const RepeatIcon = () => {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="btn/music-player/Play-style/loop">
          <g id="编组" transform="translate(0.500000, 1.500000)">
            <path
              d="M1.55981168,-8.8817842e-16 L0,1.91536613 L6,1.91536613 C10.1421356,1.91536613 13.5,5.06831882 13.5,8.95768307 C13.5,11.4541695 12.1165472,13.6472516 10.0302412,14.897863"
              id="矩形"
              transform="translate(6.750000, 7.448932) scale(-1, 1) translate(-6.750000, -7.448932) "
            ></path>
            <path
              d="M8.05981168,3 L6.5,4.91536613 L12.5,4.91536613 C16.6421356,4.91536613 20,8.06831882 20,11.9576831 C20,14.4541695 18.6165472,16.6472516 16.5302412,17.897863"
              id="矩形"
              transform="translate(13.250000, 10.448932) scale(1, -1) translate(-13.250000, -10.448932) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const RepeatedIcon = () => {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="btn/music-player/Play-style/loop">
          <g
            id="编组"
            transform="translate(0.500000, 1.500000)"
            className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
          >
            <path
              d="M1.55981168,-8.8817842e-16 L0,1.91536613 L6,1.91536613 C10.1421356,1.91536613 13.5,5.06831882 13.5,8.95768307 C13.5,11.4541695 12.1165472,13.6472516 10.0302412,14.897863"
              id="矩形"
              transform="translate(6.750000, 7.448932) scale(-1, 1) translate(-6.750000, -7.448932) "
            ></path>
            <path
              d="M8.05981168,3 L6.5,4.91536613 L12.5,4.91536613 C16.6421356,4.91536613 20,8.06831882 20,11.9576831 C20,14.4541695 18.6165472,16.6472516 16.5302412,17.897863"
              id="矩形"
              transform="translate(13.250000, 10.448932) scale(1, -1) translate(-13.250000, -10.448932) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const AddFavourites = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      fill="#ddff2b"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
        fill="none"
        fillRule="evenodd"
      >
        <g id="btn/全屏播放/收藏/default">
          <path
            d="M18.2967189,9.187261 L10.8984636,18.8575017 C10.0698919,19.7141661 9.91348598,19.7141661 9.08491431,18.8575017 L1.70312279,9.187261 C0.959728516,8.05747565 0.543336643,6.74410976 0.5,5.39242125 C0.5,2.84078962 2.46298981,0.50031658 5.2491689,0.50031658 C7.22767266,0.50031658 8.78571667,1.56149142 9.9983378,2.81957878 C11.2733313,1.63430473 12.7709026,0.5 14.7506728,0.5 C17.5368519,0.5 19.5,2.84047304 19.5,5.39210467 C19.5093731,6.75240787 19.088236,8.08085166 18.2967189,9.187261 Z"
            id="收藏"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const AddedFavourites = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="btn/全屏播放/收藏/default"
          className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
          stroke=""
        >
          <path
            d="M18.2967189,9.187261 L10.8984636,18.8575017 C10.0698919,19.7141661 9.91348598,19.7141661 9.08491431,18.8575017 L1.70312279,9.187261 C0.959728516,8.05747565 0.543336643,6.74410976 0.5,5.39242125 C0.5,2.84078962 2.46298981,0.50031658 5.2491689,0.50031658 C7.22767266,0.50031658 8.78571667,1.56149142 9.9983378,2.81957878 C11.2733313,1.63430473 12.7709026,0.5 14.7506728,0.5 C17.5368519,0.5 19.5,2.84047304 19.5,5.39210467 C19.5093731,6.75240787 19.088236,8.08085166 18.2967189,9.187261 Z"
            id="收藏"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const CommentIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
       className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
      version="1.1"
      
    >
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="btn/comment">
          <path
            d="M10,4.50042982 L17.0643091,4.50042982 C17.9112519,4.50042982 18.2183738,4.59325543 18.5280034,4.76756252 C18.837633,4.94186961 19.0806322,5.19765819 19.2462239,5.52358411 C19.4118157,5.84951004 19.4999986,6.17279628 19.4999986,7.06431502 L19.4999986,16.9365446 C19.4999986,17.8280634 19.4118157,18.1513496 19.2462239,18.4772755 C19.0806322,18.8032014 18.837633,19.05899 18.5280034,19.2332971 C18.2183738,19.4076042 17.9112519,19.5004298 17.0643091,19.5004298 L2.93569094,19.5004298 C2.08874814,19.5004298 1.78162621,19.4076042 1.47199658,19.2332971 C1.16236695,19.05899 0.919367807,18.8032014 0.753776069,18.4772755 C0.588184331,18.1513496 0.499948023,17.8280634 0.499948023,16.9365446 L0.499948023,7.06431502 C0.499948023,6.17279628 0.683102636,5.64933613 1.07754961,5.20844933 C1.47199658,4.76756252 2.07626411,4.50042982 2.62893425,4.50042982 C3.3037618,4.50042982 4.31600311,4.50042982 5.6656582,4.50042982 L4.36910376,1 L9.48219105,4.50042982 L10,4.50042982 Z"
            id="矩形"
            stroke=""
            className="stroke-color-primary dark:hover:stroke-[#ddff2b] hover:stroke-color-foreground"
            stroke-linejoin="round"
            transform="translate(9.999973, 10.250215) scale(1, -1) translate(-9.999973, -10.250215) "
          ></path>
          <path
            d="M5.5,8 L14.5,8 C14.7761424,8 15,8.22385763 15,8.5 C15,8.77614237 14.7761424,9 14.5,9 L5.5,9 C5.22385763,9 5,8.77614237 5,8.5 C5,8.22385763 5.22385763,8 5.5,8 Z"
            id="矩形"
            fill=""
            className="fill-color-primary dark:hover:fill-[#ddff2b] hover:fill-color-foreground"
          ></path>
        </g>
      </g>
    </svg>
  );
};
