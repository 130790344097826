export const category = [
  {
    id: "40217",
    name: "All",
  },
];

export const language = [
  {
    name: "All",
    id: "6",
  },
];

export const alphabet = [
  { alphabet: "Hot" },
  { alphabet: "A" },
  { alphabet: "B" },
  { alphabet: "C" },
  { alphabet: "D" },
  { alphabet: "E" },
  { alphabet: "F" },
  { alphabet: "G" },
  { alphabet: "H" },
  { alphabet: "I" },
  { alphabet: "J" },
  { alphabet: "K" },
  { alphabet: "L" },
  { alphabet: "M" },
  { alphabet: "N" },
  { alphabet: "O" },
  { alphabet: "P" },
  { alphabet: "Q" },
  { alphabet: "R" },
  { alphabet: "S" },
  { alphabet: "T" },
  { alphabet: "U" },
  { alphabet: "V" },
  { alphabet: "W" },
  { alphabet: "X" },
  { alphabet: "Y" },
  { alphabet: "Z" },
];
